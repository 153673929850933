import Layout from "components/Layout";
import HomepagePage from "templates/Homepage";
import { getRelFields } from "utils/products";
import { useTagManager } from "utils/useTagManager";
import { RobePage, getRobeProps } from "xcore";
import { Homepage, HpSlider, News } from "xcore/types";

export const getStaticProps = getRobeProps<Homepage>(({ cms }) => ({
  pageContent: cms.content.single("homepage", {
    preview: true,
    relations: v => [
      ...v.specialHPmobileMenu?.map(p => p.menuItemContentRelation?.$options([] as never[])) ?? [],
      v.nwlLinkEn,
      v.nwlLinkDe,
      v.nwlLinkRu,
      v.nwlLinkEnEN,
      v.nwlLinkFr
    ]
  }),
  content: [
    cms.content.single<HpSlider>("hpSlider", v => [
      ...getRelFields("heroSlider", cms.getCtx().locales.content).map(r => v[r]), // main carousel slides
      ...getRelFields("hpVideoSlider", cms.getCtx().locales.content).map(r => v[r]) // product videos slider
    ])
  ],
  contents: [
    cms.content.list<News>("news", {
      perPage: 4,
      includeFiles: true,
      includeCategories: true,
      fields: ["title", "intro", "images", "size"],
      sort: ["publishedAt", "desc"]
    })
  ],
  categories: [cms.taxonomy.list("news-categories")]
}));

const Index: RobePage = () => {
  useTagManager("homepage");

  return (
    <HomepagePage />
  );
};

Index.Layout = Layout;

export default Index;
