import { Button, ButtonProps, ButtonVariant, ButtonSize } from "@xcorejs/ui";
import { FC, ReactNode, AnchorHTMLAttributes } from "react";

import YoutubeIcon from "design-system/appio/icons/youtube.svg";
import { darken } from "polished";

export interface ButtonPropsWithIcons extends ButtonProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  loading?: boolean;

  size?: ButtonSize;
  s?: ButtonSize;
  variant?: ButtonVariant;
  v?: ButtonVariant;
}

export type ExtendedButtonProps =
  & ButtonPropsWithIcons
  & ({ as?: "button" | "div" } | ({ as: "a" } & AnchorHTMLAttributes<unknown>));

const YoutubeButton: FC<ExtendedButtonProps> = ({ children, ...props }) => (
  <Button
    leftIcon={<YoutubeIcon />}
    bg="#ff0000"
    px="3rem"
    _leftIcon={{ mr: "1.5rem", fill: "#fff" }}
    _hover={{ bg: darken(0.025, "#ff0000") }}
    _active={{ bg: darken(0.05, "#ff0000") }}
    _focus={{
      bg: darken(0.05, "#ff0000"),
      outline: "2px solid rgba(15, 31, 40, 0.2)",
      outlineOffset: "-2px"
    }}
    {...props}
  >
    {children}
  </Button>
);

export default YoutubeButton;
