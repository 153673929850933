import { Button } from "@xcorejs/ui";
import { FC } from "react";

import VimeoIcon from "design-system/appio/icons/vimeo.svg";
import { ExtendedButtonProps } from "./Youtube";
import { darken } from "polished";

const VimeoButton: FC<ExtendedButtonProps> = ({ children, size, ...props }) => (
  <Button
    leftIcon={<VimeoIcon />}
    bg="#1ab7ea"
    px="3rem"
    _leftIcon={{ mr: "1.5rem", fill: "#fff" }}
    _hover={{ bg: darken(0.025, "#1ab7ea") }}
    _active={{ bg: darken(0.05, "#1ab7ea") }}
    _focus={{
      bg: darken(0.05, "#1ab7ea"),
      outline: "2px solid rgba(15, 31, 40, 0.2)",
      outlineOffset: "-2px"
    }}
    {...props}
  >
    {children}
  </Button>
);

export default VimeoButton;
